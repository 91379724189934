<script type="text/javascript">
    // 定义一些公共的属性和方法
    const httpUrl = 'https://blog-api.ifanlee.com/admin/';
    const httpCode = {
        succ:0,
        err404:404,
        errNologin:-1
    };
    function updateApiToken(token) {
        window.localStorage.setItem('apiToken',token)
    }
    function updateUserInfo(userInfo) {
        window.localStorage.setItem('userInfo',userInfo)
    }
    function getUserInfo() {
        var u = window.localStorage.getItem('userInfo');
        return JSON.parse(u)
    }
    function checkLogin() {
        var u = window.localStorage.getItem('apiToken');
        return u==""?false:true
    }
    function timestampToTime(timestamp) {
        var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = date.getDate();
        var h = date.getHours();
        var m = date.getMinutes();
        var s = date.getSeconds();
        var DD = (D>9?D:'0'+D)+" ";
        var hh = (h>9?h:'0'+h)+":";
        var mm = (m>9?m:'0'+m)+":";
        var ss = (s>9?s:'0'+s)+"";
        return Y+M+DD+hh+mm+ss;
    }
    // 暴露出这些属性和方法
    export default {
        httpUrl,
        httpCode,
        updateApiToken,
        updateUserInfo,
        getUserInfo,
        checkLogin,
        timestampToTime
    }
</script>