<template>
    <div class="sidebar">
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#324157"
            text-color="#bfcbd9" active-text-color="#20a0ff" unique-opened router>
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                                    {{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            router:true,
            collapse:this.$store.state.collapse,
            items : [
                {
                    icon: "el-icon-s-home",
                    index: "/dashboard",
                    title: "系统首页",
                },
                {
                    icon: "el-icon-reading",
                    index: "/article",
                    title: "文章列表",
                },
                {
                    icon: "el-icon-collection-tag",
                    index: "/tag",
                    title: "标签列表",
                },
                // {
                //     icon: "el-icon-lx-calendar",
                //     index: "3",
                //     title: "文章管理",
                //     subs: [
                //         {
                //             index: "/form",
                //             title: "文章列表",
                //         },
                //         {
                //             index: "/form",
                //             title: "新增文章",
                //         },
                //
                //
                //
                //         // {
                //         //     index: "4",
                //         //     title: "三级菜单",
                //         //     subs: [
                //         //         {
                //         //             index: "/editor",
                //         //             title: "富文本编辑器",
                //         //         },
                //         //     ],
                //         // },
                //     ],
                // },

            ]
        }
    },
    mounted:function (){

    },
    methods: {

    },
    computed: {
        onRoutes:function () {
            return this.$route.path;
        },
        col:function(){
            return this.$store.state.collapse;
        }
    },
    watch:{
        col(bool){
            this.collapse = bool;
        }
    }
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
