
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import global from './components/common.vue'
Vue.prototype.common = global;
Vue.prototype.axios = axios;
// import './assets/css/icon.css'
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');


const urlWhite = ["login"];

const service = axios.create({
    baseURL: "https://blog-admin-api.fanlee.cn/admin/v1", // api的base_url
    timeout: 5000 // request timeout
});

axios.interceptors.request.use(config => {
    let newConfig = config;
    //不需要token的请求
    for (const i of urlWhite) {
        if (newConfig.url.indexOf(i) != -1) {
            return newConfig;
        }
    }
    let token = window.localStorage.getItem("apiToken");
    if (token) {// 判断是否存在token，如果存在的话，则每个http header都加上token
        newConfig.headers.token = token;
    }
    return newConfig
}, error => {
    return Promise.reject(error)
});

axios.interceptors.response.use(
    response => {
        if (response.data.code==-1){
            window.localStorage.removeItem("userInfo");
            window.localStorage.removeItem("apiToken");
            window.location.href = "/login"
      }else {
            return response
        }
    },
    error => {
// store.dispatch('hideLoading');
// // 非法的身份或者身份过期，需要重新登录 // 先退出，在登录 && error.response.config.url.indexOf('/ycloud-user/users/logOut') === -1)
        console.log("resp err",error)
    });