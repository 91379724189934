import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  }, {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: '系统首页'
        },
        component: () => import ( /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
      }, {
        path: "/article",
        name: "article",
        meta: {
          title: '文章'
        },
        component: () => import ( /* webpackChunkName: "icon" */ "../views/ArticleList.vue")
      }, {
        path: '/tag',
        name: 'tag',
        meta: {
          title: '标签'
        },
        component: () => import (/* webpackChunkName: "editor" */ '../views/TagList.vue')
      }, {
            path: '/article/add',
            name: 'articleAdd',
            meta: {
                title: '新增文章'
            },
            component: () => import (/* webpackChunkName: "editor" */ '../views/ArticleAdd.vue')
        }
      ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (!window.localStorage.getItem('apiToken')&&to.path !== '/login'){
        next('/login');
    }else {
        next();
    }
  //   const role = localStorage.getItem('ms_username');
  //   if (to.path !== '/login') {
  //       next('/login');
  //   } else {
  //       next();
  //   }
  //
  //
  //
  // console.log(to)
  //   console.log(from)
  //
  // next();

});
export default router
