<template>
    <div class="about">
        <v-header />
        <v-sidebar />
        <div class="content-box" :class="{ 'content-collapse': collapse }">
<!--            <v-tags></v-tags>-->
            <div class="content">
                <router-view v-slot="{ Component }">
                    <transition name="move" mode="out-in">
                        <keep-alive :include="tagsList">
                            <component :is="Component" />
                        </keep-alive>
                    </transition>
                </router-view>
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "../components/Header.vue";
import vSidebar from "../components/Sidebar.vue";

export default {
    name: 'Login',
        components: {
        vHeader,
        vSidebar,
    },
    data() {
        return {
            collapse :this.$store.state.collapse,
        }
    },
    mounted:function (){

    },
    methods: {

    },
    computed: {
        col:function(){
            return this.$store.state.collapse;
        }
    },
    watch:{
        col(bool){
            this.collapse = bool;
        }
    }
}
</script>
